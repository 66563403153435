<template>
  <div>
    <div :class="'split-top' + terminalWindow.state">
      <b-tabs class="h-100 overflow-auto">
        <b-tab title="Ops" active v-if="$debug.isOn">
          <ops class="m-2" @run-command="runCommand" />
        </b-tab>
        <b-tab title="Projects">
          <projects class="m-2" @run-command="runCommand" />
        </b-tab>
        <b-tab title="Assets">
          <div v-if="false">Comming soon...</div>
          <Assets class="m-2" @run-command="runCommand" v-else />
        </b-tab>
        <b-tab title="Game">
          <game class="m-2" />
        </b-tab>
        <b-tab title="Engines">
          <engines class="m-2" @run-command="runCommand" />
        </b-tab>
      </b-tabs>
    </div>
    <div :class="'split-bottom' + terminalWindow.state">
      <div class="bg-dark border-bottom text-light d-block d-inline">
        <div>
          &nbsp;Terminal
          <b-spinner small v-show="commandRunning" />
          <div class="float-right"> 
            <b-button class="badge" @click="terminalWindow.noScroll = !terminalWindow.noScroll">
              <font-awesome-icon icon="play" :style="{ color: 'white' }" size="sm" v-if="terminalWindow.noScroll"/>
              <font-awesome-icon icon="pause" :style="{ color: 'white' }" size="sm" v-else/>
            </b-button>
            <b-button class="badge" @click="terminalWindow.state = !terminalWindow.state ? '-full' : ''"><font-awesome-icon icon="maximize" :style="{ color: 'white' }" size="sm"/></b-button>
            <b-button class="badge" @click="output=''"><font-awesome-icon icon="trash" :style="{ color: 'white' }" size="sm"/></b-button>
          </div>
        </div>
      </div>
      <div class="h-100 overflow-auto" ref="output">
        <pre><span v-html="output"></span></pre>
      </div>
    </div>
  </div>
</template>

<script>
import { runCommand } from './shivarun';

export default {
  components: {
    Ops: () => import("./Ops.vue"),
    Projects: () => import("./Projects.vue"),
    Game: () => import("./Game.vue"),
    Assets: () => import("./Assets.vue"),
    Engines: () => import("./Engines.vue"),
  },
  data() {
    return {
      output: "",
      commandRunning: false,

      terminalWindow: {
        state: '',
        noScroll: false,
      },
    };
  },
  mounted() {
  },
  methods: {
    runCommand(command, args, options, complete) {
      this.output += `🐓 Running command: ${command} ${args.join(" ")}\n`;
      this.commandRunning = true;
      this.terminalWindow.state = '-mid';
      let nestedElement = this.$refs.output;
      runCommand(command, args, options, (line) => {
        let linelc = line.toLowerCase();
        if (linelc.includes("error:"))
          line = `<span style="background-color:#FCC;">${line}</span>`;
        else if (linelc.includes("warning:"))
          line = `<span style="background-color:#FFA;">${line}</span>`;
        this.output += line;
        if (!this.terminalWindow.noScroll)
          nestedElement.scrollTo(0, nestedElement.scrollHeight);
      }, (errorCode) => {
        this.output += '-- Command complete\n';
        this.commandRunning = false;
        this.terminalWindow.state = '';
        if (complete) complete(errorCode);
      });
    }
  },
};
</script>

<style css scoped>
pre {
 white-space: pre-wrap;
}

.split-top {
  height: calc(75vh - 58px);
}

.split-bottom {
  height: calc(25vh - 24px);
}

.split-top-mid {
  height: calc(50vh - 58px);
}

.split-bottom-mid {
  height: calc(50vh - 24px);
}

.split-top-full {
  height: 0;
}

.split-bottom-full {
  height: calc(100vh - 24px - 58px);
}
</style>