import { getLog } from '@/services/log';
let log = getLog('shivarun');

export function needElectronUpdate() {
  let res = (window.electron && !window.electron.openExternal) || false;
  log.log('needElectronUpdate', res);
  return res;
}

export async function getEnv() {
  if (window.electron) {
    log.log('getEnv', 'electron');
    return await window.electron.getEnv();
  }
  else {
    log.log('getEnv', 'dummy answer true');
    return {"dummy": "true"};
  }
}

export async function openExternal(url) {
  if (window.electron) {
    log.log('openExternal', 'electron', url);
    await window.electron.openExternal(url);
  }
  else {
    log.log('openExternal fallback');
    window.open(url, '_blank');
  }
}

export function isValidPath(path) {
  return /^[a-zA-Z]:\\([a-zA-Z0-9_ ]+\\)*\w*$/.test(path);
}

// Trims the path, and double '\' characters
export function cleanupFilename(filename) {
  return filename.trim().replace(/\\/g, '\\\\');
}

export function runCommand(command, args, options, output, complete) {
  if (window.electron) {
    let newComplete = () => {
      window.electron.beep();
      if (complete) complete();
    }
    window.electron.exec(command, args, options, output, newComplete);
  }
  else
    return runCommandDummy(command, args, options, output, complete);
}

function runCommandDummy(command, args, options, output, complete) {
  log.log('runCommand', command, args);
  output('Electron is not detected, running dummy command.\n');
  setTimeout(() => {
    complete();
  }, 1000);
}

export async function checkFolderExists(folder) {
  // log.log('checkFolderExists', folder);
  if (window.electron) {
    let res = await window.electron.checkFolderExists(folder);
    // log.log('checkFolderExists', 'electron', 'res=', res);
    return res;
  }
  else {
    // log.log('checkFolderExists', 'dummy answer true');
    return true;
  }
}

export async function checkFileChecksum(filepath) {
  if (window.electron) {
    let res = await window.electron.checkFileChecksum(filepath);
    log.log('checkFileChecksum', 'electron', 'res=', res);
    return res;
  }
  else {
    log.log('checkFileChecksum', 'dummy answer true');
    return "";
  }
}